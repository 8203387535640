<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">任务完成排行榜</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board :config="config" style="width:100%;height:4.28rem" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	computed:{
		...mapState(['info']),
		config(){
			const { act_info } = this.info
			const data = act_info.map(x=>{
				const total = x.woman + x.man
				return [
					x.name,
					x.act_count,
					total,
					`${x.woman}(${Math.round((x.woman/total) * 100)}%)`,
					`${x.man}(${Math.round((x.man/total) * 100)}%)`
				]
			})
			return {
				header: ["活动名称", "总举办次数","总参与数","女士参与","男士参与"],
				data,
				rowNum: 6, //表格行数
				headerHeight: 35,
				headerBGC: "#0f1325", //表头
				oddRowBGC: "#0f1325", //奇数行
				evenRowBGC: "#171c33", //偶数行
				index: true,
				columnWidth: [70],
				align: ["center","center","center","center","center","center"]
			}
		}
	}
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
</style>
