<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <p class="ml-3 colorBlue fw-b">{{ item.title }}</p>
        <div>
          <dv-digital-flop
            :config="item.number"
            style="width: 1.25rem; height: 0.625rem"
          />
        </div>
      </div>
    </div>
    <div class="down">
      <dv-active-ring-chart
        :config="sex"
        style="width: 300px; height: 300px"
      />
      <dv-active-ring-chart
        :config="age"
        style="width: 300px; height: 300px"
      />
      <dv-active-ring-chart
        :config="birth"
        style="width: 300px; height: 300px"
      />
    </div>
  </div>
</template>

<script>
import CenterChart from "@/components/echart/center/centerChartRate";
import { mapState } from "vuex"
export default {
	data() {
		return {

		};
	},
	components: {
		CenterChart,
	},
  	computed: {
		...mapState(['info']),
		titleItem() {
			const title = ["中心长者总人数","中心活动总数","今年已举办活动次数","今年累活动参与人数","昨日微信签到人数","昨日活动参与人数"]
			const { total_info } = this.info
			return title.map(title => {
				let val
				switch (title) {
					case "中心长者总人数":
						val = total_info.total_people
						break;
					case "中心活动总数":
						val = total_info.total_act
						break;
					case "今年已举办活动次数":
						val = total_info.total_act_year
						break;
					case "今年累活动参与人数":
						val = total_info.total_act_people
						break;
					case "昨日微信签到人数":
						val = total_info.total_wx_sign
						break;
					case "昨日活动参与人数":
						val = total_info.total_act_yesterday
						break;
				}
				return {
					title,
					number:{
						number:[val],
						toFixed: 0,
						content: "{nt}",
					}
				}
			})
		},
		sex(){
			const { total_info:{ sex } } = this.info
			return {
				radius: "40%",
				activeRadius: "45%",
				data: [
				{
					name: "男",
					value: sex.total_man,
				},
				{
					name: "女",
					value: sex.total_women,
				},
				],
				digitalFlopStyle: {
					fontSize: 15,
				},
				color: ["#37a2da", "#fb7293"],
			}
		},
		age(){
			const { total_info:{ age } } = this.info
			return {
				radius: "40%",
				activeRadius: "45%",
				data: [
					{
						name: "90岁以上",
						value: age[4],
					},
					{
						name: "80-90岁",
						value: age[3],
					},
					{
						name: "70-80岁",
						value: age[2],
					},
					{
						name: "60-70岁",
						value: age[1],
					},
					{
						name: "60岁以下",
						value: age[0],
					},
				],
				digitalFlopStyle: {
					fontSize: 15,
				},
			}
		},
		birth(){
			const { total_info:{ birth } } = this.info
			const data = birth.map((x,i)=>{
				return {
					name:`${i+1}月生日`,
					value:x
				}
			})
			return {
				radius: "40%",
				activeRadius: "45%",
				data,
				digitalFlopStyle: {
					fontSize: 15,
				},
			}
		}
  	},
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 32%;
      height: 0.875rem;
    }
  }
  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 3.1875rem;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 59%;
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
</style>
