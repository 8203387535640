<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Chart from "./chart.vue";
export default {
	components: {
		Chart,
	},
	computed:{
		...mapState(['info']),
		cdata(){
			const { addr } = this.info
			const category = addr.map(x => x._id)
			const data = addr.map(x => x.count)
			return {
				category,
				data
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
