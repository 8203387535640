<template>
  <div id="centreLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">中心长者微信</span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
      </div>
      <div class="d-flex jc-center">
        <dv-scroll-board
          :config="config"
          style="width: 3.375rem; height: 4.28rem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  	data() {
		return {
			config: {
				header: ["姓名", "积分", "头像"],
				data: [],
				rowNum: 5, //表格行数
				headerHeight: 35,
				headerBGC: "#0f1325", //表头
				oddRowBGC: "#0f1325", //奇数行
				evenRowBGC: "#171c33", //偶数行
				index: false,
				// columnWidth: [200,200],
				align: [],
			},
		}
  	},
	computed:{
		...mapState(['info'])
	},
	watch:{
		info:{
			deep:true,
			immediate:true,
			handler(){
				const { wx_people } = this.info
				this.config = {
					...this.config,
					data:wx_people.map(x=>{
						return [
							x.bind_id.name,
							x.bind_id.point - x.bind_id.del_point,
							`<img class="img_url" src="${x.avatarUrl}">`
						]
					})
				}
			}
		}
	}
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
.img_url {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
</style>
